import React, { useState } from "react";
import { Dropdown, Menu, Spin, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./Dropdown.css";

const { Text } = Typography;

const CustomDropdown = ({
  artPieceInformation,
  onLocationChange,
  selectedLocationName,
  selectedLocationId,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleVisibleChange = (visible: any) => {
    setIsOpen(visible);
  };
  // find out what is the location that is the latest and set it as selected
  // create items from artPieceInformation.locationHistory
  const items = artPieceInformation.locationHistory.map(
    (location: any, index: any) => ({
      key: index.toString(),
      label: (
        <a target="_blank" rel="noopener noreferrer">
          {location.location.name}
          {location.id === selectedLocationId && (
            <FontAwesomeIcon
              icon={faCheck}
              color="#B22E40"
              style={{ marginLeft: "5px" }}
            />
          )}
        </a>
      ),
    })
  );

  const handleMenuClick = ({ key }: any) => {
    const location = artPieceInformation.locationHistory[key];
    onLocationChange(location.id, location.location.name);
  };

  return (
    <div className="dropdown-container">
      <Dropdown
        menu={{ items, onClick: handleMenuClick }}
        trigger={["click"]}
        onOpenChange={handleVisibleChange}
        overlayStyle={{ margin: "5px" }} // Add margin here
      >
        <div className="dropdown-header" onClick={(e) => e.preventDefault()}>
          <Text
            className="dropdown-title"
            underline
            style={{ color: "#B22E40", fontWeight: "bold" }}
          >
            {selectedLocationName}
          </Text>
          <FontAwesomeIcon
            className={`dropdown-arrow ${isOpen ? "open" : ""}`}
            style={{ marginLeft: 8, transition: "transform 0.2s ease" }}
            icon={faChevronDown}
            color="white"
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default CustomDropdown;
