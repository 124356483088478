import "./style.css";
import { Navbar } from "../Navbar";
import DMNICON from '../../assets/DMNICON.png'
import ARTIMAGE from './art-image.png'
import artistwhite from '../../assets/artistwhite.png'
import connectwhite from '../../assets/connectwhite.png'
import gallerywhite from '../../assets/gallerywhite.png'
import shakewhite from '../../assets/shakewhite.png'
import dmnfullicon from './DMNFULLICON.png'
import instagramImg from '../../assets/instagram 1.png'
import youTubeImg from '../../assets/youtube 1.png'
import discordImg from '../../assets/discord-outline.png'
import { Link, useLocation } from "react-router-dom";
import productImage from './Product-screenshot.png'
import { useEffect } from "react";

const productAndFeatures = [
  {
    title: "Artist",
    description: "Streamlined selling process, enhanced discoverability, and professional tools for artists.",
    buttonText: "Try Free",
    img: artistwhite,
  },
  {
    title: "Collector",
    description: "Manage collections and deal flow, discover new works, and facilitate transactions effortlessly.",
    buttonText: "Coming Soon",
    img: shakewhite,
  },
  {
    title: "Gallery",
    description: "Gallery management, sales, event submissions, and artist collaboration all in one.",
    buttonText: "Coming Soon",
    img: gallerywhite,
  },
  {
    title: "Marketplace",
    description: `Manage collections and deal flow, discover new works, and facilitate transactions effortlessly.`,
    buttonText: "Coming Soon",
    img: connectwhite,
  }
]

export const LandingPage = (): JSX.Element => {
  const location = useLocation();

  const handleJoinWaitlist = () => {
    window.open('http://mailchi.mp/d7542d75943a/waitlist', '_blank');
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  return (
    <>
      <div className="landing-page" id="landing-page">
        <div className="background-wrp" />
        <Navbar />

        <div className="page-content-container">
          <div className="hero-section">
            <div className="content-container">
              {/* <h1 className="content-title">Pulse</h1> */}
              <h1 className="content-subtitle">Simplify Your Art Sales. Focus on Creating.</h1>
              <p className="content-details">From inventory management to tag creation, Pulse simplifies the selling process so you can focus on your art.</p>
              <div className="content-button-container">
                {/* <button className="content-button">Explore Pulse</button> */}
                <button onClick={handleJoinWaitlist} className="content-button">Learn More</button>
              </div>
            </div>
            <div className="hero-image-container">
              <img src={productImage} alt=" " />
            </div>
            <div className="content-button-container-mobile">
              <button onClick={handleJoinWaitlist} className="content-button">Learn More</button>
            </div>
          </div>

          {/* <div className="product-feature-section" id="product" >
            <div className="product-feature-title">Product & Features</div>
            <div className="product-feature-description">A unified solution for artists, galleries, collectors, and consumers—manage, sell, and connect seamlessly.</div>

            <Row gutter={[200, 50]} className="features-card-container">
              {
                productAndFeatures.map((product, index) => {
                  return (
                    <Col span={24} xs={24} lg={24} xl={12} className="feature-card-wrapper" key={index}>
                      <div className="feature-card">
                        <div className="feature-title">{product.title}</div>
                        <div className="feature-body">
                          <div className="img-container">
                            <img src={product.img} alt="artist" className="feature-img" />
                          </div>

                          <div className="text-container">
                            <div className="feature-description">{product.description}</div>
                            <button className="feature-button">{product.buttonText}</button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
          </div>

          <div className="artLink-concierge-section">
            <div className="artLink-concierge-logo">
              <img src={DMNICON} alt="DMNICON" className="concierge-img" />
            </div>
            <div className="artLink-concierge-title">Pulse Concierge</div>
            <div className="artLink-concierge-description">Pulse Concierge offers a premium, white-glove art service that seamlessly blends technology and media to sell artwork in innovative and creative ways. <br /> <br /> As an Pulse Concierge client, you'll gain exclusive access to our expert advisory and media teams. We’ll appraise, catalog, and list your collections on Pulse, while collaborating with you to craft campaigns and strategies designed to elevate your profile as an artist.</div>
            <button className="concierge-button">Join Waitlist</button>
          </div> */}

          <div className="power-of-art-section">
            <div className="power-of-art-logo">
              <img src={DMNICON} alt="DMNICON" className="concierge-img" />
            </div>
            <div className="power-of-art-description">We believe in the power of art to bring people together and aim to make art accessible, impactful, and enduring.</div>
          </div>

          <div className="Join-Wait-list-section">
            <div className="Join-Wait-list-left">
              <div className="Join-Wait-list-description">To make this vision a reality, we partner with artists, providing hands-on support to showcase their work and connect it with the world through documentation, promotion, and our unique sales and media channels.</div>
              <button onClick={handleJoinWaitlist} className="Join-Wait-list-button">Join Waitlist</button>
            </div>
            <div className="Join-Wait-list-right">
              <div className="art-image">
                <img src={ARTIMAGE} alt="ARTIMAGE" className="concierge-img" />
              </div>
              <div className="artist-details">
                <Link to={'https://pulse.art/piece/66fb59e474c408888a4087dc'} className="artist-link">The Opportunity, to Pause</Link>
                <div className="">Talia Swartz Parsell</div>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="logo-image">
              <img src={dmnfullicon} alt="DMNlogo" className="logo-img" />
            </div>
            <div className="text">
              The Rhythm of Creativity. The Future of Art.
            </div>
            <div className="footer-icon">
              <Link target="_blank" to={'https://www.instagram.com/pulsevisualart '} className="icon">
                <img src={instagramImg} alt="InstagramLogo" className="instagram-img" />
              </Link>
              <Link target="_blank" to={'https://www.youtube.com/channel/UCwdK9iEEkfDVMnh_uI62GPw '} className="icon">
                <img src={youTubeImg} alt="youTubeLogo" className="youtube-img" />
              </Link>
              <Link target="_blank" to={'https://discord.gg/CnEAeknh '} className="icon">
                <img src={discordImg} alt="TwistLogo" className="twist-img" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
