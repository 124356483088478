import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./ArtistHomepage.css";
import { Popover, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
import HomePageContent from "../components/HomePageContent";
import TopBar from "../components/TopBar";
import Lottie from "react-lottie";
import { scrolldown } from "../components/scrolldown";

const ArtistHomepage: React.FC = () => {
  const topBarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [artPiecesLoaded, setArtPiecesLoaded] = useState(false);
  const [artPieces, setArtPieces] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [user, setUser] = useState<any>([]);
  const [updatedAnimationData, setUpdatedAnimationData] = useState<any>(null);


  /**
   * Fetches all pieces by the artist's uid
   * @param artistUid
   * @returns
   */
  const getAllPiecesByArtistUid = async (artistUid: string) => {
    try {
      setArtPiecesLoaded(false);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/all/${artistUid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const piecesResponse = await response.json();
      setArtPieces(piecesResponse);
    } catch (error) {
      console.log(error);
      // Handle error state as well, if necessary
    } finally {
      // If you have a loading state, you would set it to false here
      setIsLoading(false);
      setArtPiecesLoaded(true);
    }
  };

  const getUserByUid = async (uid: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/${uid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const userResponse = await response.json();
      setUser(userResponse);
      return userResponse;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true); // Start loading
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        await getUserByUid(uid);
        await getAllPiecesByArtistUid(uid);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
    if (topBarRef.current) {
      // @ts-ignore
      topBarRef.current.tabIndex = -1;
      // @ts-ignore
      topBarRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (artPieces.length === 0 && artPiecesLoaded) {
      setPopoverVisible(true);
      const timer = setTimeout(() => {
        console.log("setting to false");
        setPopoverVisible(false);
      }, 5000);

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [artPieces]);

  useEffect(() => {
    const newAnimationData = updateStrokeColor({ ...scrolldown });
    setUpdatedAnimationData(newAnimationData);
  }, []);

  const navigate = useNavigate();
  const handleNewButton = () => {
    navigate("/home/newPiece");
  };

  const handlePiece = (id: string) => {
    if (id === "test") navigate("/home/piece");
    else navigate(`/home/piece/${id}`);
  };

  const handlePieceWithLocation = (piece: any) => {
    navigate(`/home/piece/${piece._id}`);
  };

  const triggerModal = () => {
    setShowModal(!showModal);
  };

  const logOutHandler = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      console.log("Logged out");
      navigate("/");
    });
  };

  const settingsHandler = () => {
    navigate("/settings");
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const updateStrokeColor = (animationData: any) => {
    animationData.layers.forEach((layer: any) => {
      if (layer.shapes) {
        layer.shapes.forEach((shape: any) => {
          if (shape.it) {
            shape.it.forEach((item: any) => {
              if (item.ty === "st") { 
                item.c.k = [0.698, 0.18, 0.251];
              }
            });
          }
        });
      }
    });
    return animationData;
  };


  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.8 },
  };

  const content = (
    <div>
      <p>Create a New Piece to Get Started!</p>
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "#1f2129",
        color: "white",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "25px",
        paddingBottom: "50px",
      }}
    >
      <AnimatePresence>
        {!isLoading && artPiecesLoaded ? (
          <motion.div
            key="content"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
          >
            <TopBar topBarRef={topBarRef} triggerModal={triggerModal} />
            <HomePageContent
              user={user}
              artPieces={artPieces}
              handlePiece={handlePiece}
              handlePieceWithLocation={handlePieceWithLocation}
            />
            {/* Bottom Buttons */}
            <div
              style={{
                display: "flex",
                position: "fixed",
                justifyContent: "space-evenly",
                left: 0,
                bottom: 0,
                width: "100%",
                backgroundColor: "#1f2129",
                color: "white",
                textAlign: "center",
                padding: "10px 0", // Adjust as needed
                zIndex: 1000, // Ensure it's above other content
              }}
            >
              {/* New Button */}
              <Popover
                content={content}
                title="Quick Tip!"
                open={popoverVisible}
              >
                <button
                  onClick={handleNewButton}
                  style={{
                    width: "125px",
                    backgroundColor: "#B22E40",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "5px",
                    fontWeight: "bold",
                  }}
                >
                  New Piece
                </button>
              </Popover>
            </div>
          </motion.div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 100,
                    color: "#B22E40",
                  }}
                  spin
                />
              }
            />
          </div>
        )}
      </AnimatePresence>
      <div
        style={{
          position: "fixed",
          right: "-50px",
          bottom: "20px",
          margin: "20px", // Adjust the margin as needed
          color: "#B22E40"
        }}
        hidden={artPieces.length >= 6 ? false : true}
      >
        <Lottie
          options={{
            // loop for 5 seconds, then stop
            loop: 3,
            autoplay: true,
            animationData: updatedAnimationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={"25%"}
          width={"25%"}
        />
      </div>
      <AnimatePresence>
        {showModal && (
          <motion.div
            key="modal"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
            onClick={() => setShowModal(false)}
          >
            <motion.div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(31, 33, 40, 0.95)",
                padding: "20px",
                borderRadius: "20px",
                border: "5px solid #B22E40",
                minWidth: "250px",
                zIndex: 1001, // Above the button container
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setShowModal(false)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                  cursor: "pointer",
                }}
              />
              <div id="hi" style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#B22E40",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      width: "150px",
                      height: "45px",
                    }}
                    onClick={logOutHandler}
                  >
                    Log Out
                  </button>
                  <button
                    style={{
                      backgroundColor: "#B22E40",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      width: "150px",
                      height: "45px",
                    }}
                    onClick={settingsHandler}
                  >
                    Settings
                  </button>
                  <button
                    style={{
                      backgroundColor: "#B22E40",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      width: "150px",
                      height: "45px",
                    }}
                    onClick={() => {
                      navigate("/artist-setup-page");
                      setShowModal(false);
                    }}
                  >
                    View/Edit Bio
                  </button>
                  <button
                    style={{
                      backgroundColor: "#B22E40",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                      fontWeight: 700,
                      fontSize: "16px",
                      width: "150px",
                    }}
                    onClick={() => {
                      navigate("/stripe-onboarding");
                      setShowModal(false);
                    }}
                  >
                    Onboard Stripe
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ArtistHomepage;
