import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Waitlist = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = "http://mailchi.mp/d7542d75943a/waitlist";
  }, [navigate]);

  return <></>;
};

export default Waitlist;
