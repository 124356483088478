import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CompletedOnboardingPage = () => {
  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false);
  const navigate = useNavigate();
  const { accountId } = useParams();
  const getConnectedAccountDetails = async (accountId: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/user/stripe/connected-account/${accountId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  };
  useEffect(() => {
    const checkIfOnboardingComplete = async () => {
      if (accountId) {
        const accountDetails = await getConnectedAccountDetails(accountId);
        //@ts-ignore
        if (accountDetails.details_submitted) {
          setIsOnboardingComplete(true);
        }
      }
    };
    checkIfOnboardingComplete();
  }, [accountId]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: "16px",
            fontWeight: 900,
            lineHeight: "41.86px",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          {isOnboardingComplete
            ? "You're all set! Welcome to the platform. 🎉"
            : "Looks like you need to complete onboarding. Please return home and try again."}
        </div>
        <button
          style={{
            fontWeight: "bold",
            width: "125px",
            backgroundColor: "#B22E40",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            marginTop: "20px",
          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Return home
        </button>
      </div>
    </>
  );
};

export default CompletedOnboardingPage;
