import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const StripePurchaseCancel: React.FC = () => {
  const navigate = useNavigate();
  const topBarRef = useRef(null);
  const { linkId } = useParams();

  return (
    <>
      <div
        style={{
          backgroundColor: "#1f2129",
          color: "white",
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "25px",
        }}
      >
        <FontAwesomeIcon
          // left align
          style={{ marginRight: "auto", marginLeft: "5px" }}
          size="xl"
          icon={faAngleLeft}
          color="white"
          onClick={() => navigate("/home")}
        />
        <div
          ref={topBarRef}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            alignSelf: "flex-end",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "#1f2129",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center content vertically
            alignItems: "center", // Center content horizontally
            paddingTop: "25px",
          }}
        >
          You have cancelled your purchase.
        </div>
        {/* Main Content */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center children horizontally in the container
            justifyContent: "center", // Center children vertically in the container
            margin: "20px",
            gap: "20px", // Adds space between the input and the QRCodeGenerator
          }}
        >
          <button
            style={{
              backgroundColor: "#B22E40",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              fontWeight: 700,
              fontSize: "16px",
              width: "150px",
              // height: "45px",
              marginBottom: "25px", // Space between buttons
            }}
            onClick={() => {
              navigate(`/artpiece/${linkId}`);
            }}
          >
            Click to return to art piece
          </button>
        </div>
      </div>
    </>
  );
};

export default StripePurchaseCancel;
