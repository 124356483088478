import { useEffect, useState } from "react";
import PhoneNumberInput from "../components/PhoneNumberInput";
import { useNavigate } from "react-router-dom";
import DMNLogo from "../components/DMNLogo";
import { auth } from "../firebase.config.js";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Lottie from "react-lottie";
import { load } from "../components/loading";
import { useAuth } from "../context/AuthContext";

const LoginScreen = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isCaptchaFailed, setIsCaptchaFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     if (currentUser) {
  //       navigate("/home");
  //     }
  //   }, [currentUser]);

  const initializeCaptcha = () => {
    // @ts-ignore
    if (!window.recaptchaVerifier) {
      // @ts-ignore
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "login-screen", {
        size: "invisible",
        // @ts-ignore
        callback: (response) => {
          // Call the sign-in function only when the captcha is successfully verified
          signIn();
        },
        "expired-callback": () => {
          console.log(
            "Captcha Expired - Consider refreshing the captcha or alert the user!"
          );
        },
      });
    }
  };

  const signIn = async () => {
    try {
      // to enable recaptcha, uncomment all these lines in the scope except the tsignores
      // @ts-ignore
      //   const appVerifier = window.recaptchaVerifier;

      //   const confirmationResult = await signInWithPhoneNumber(
      //     auth,
      //     phoneNumber,
      //     appVerifier
      //   );
      //   // @ts-ignore
      //   window.confirmationResult = confirmationResult;
      //   // @ts-ignore
      //   window.recaptchaVerifier.verify();
      navigate("/register");
    } catch (error) {
      //   setIsCaptchaFailed(true);
      console.error("Error during sign-in:", error);
    }
  };

  const onSignupButtonClick = () => {
    // Start loading
    setIsLoading(true);

    setTimeout(() => {
      // Stop loading and navigate after 2 seconds
      setIsLoading(false);
      navigate("/register-demo");
    }, 2000);
  };

  return (
    <div
      style={{
        backgroundColor: "#1f2129",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DMNLogo />

      {/* Phone Number Input */}
      <PhoneNumberInput setPhone={setPhoneNumber} phone={phoneNumber} />

      {/* Log In Button */}
      {isLoading ? (
        <button
          //  uncomment this next line and comment the signIn line for recaptcha
          onClick={onSignupButtonClick}
          // onClick={signIn}
          disabled={isLoading}
          style={{
            // cursor: "pointer",
            marginTop: "20px",
            backgroundColor: "#B22E40",
            color: "white",
            padding: "0px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            display: "flex", // Use flex layout
            alignItems: "center", // Center items vertically
            justifyContent: "center", // Center items horizontally (if you want)
          }}
          id="verify-button"
        >
          Sending Code
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: load,
            }}
            height={50}
            width={50}
          />
        </button>
      ) : (
        <button
          //  uncomment this next line and comment the signIn line for recaptcha
          onClick={onSignupButtonClick}
          style={{
            cursor: "pointer",
            marginTop: "20px",
            backgroundColor: "#B22E40",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontSize: "1rem",
            display: "flex", // Use flex layout
            alignItems: "center", // Center items vertically
            justifyContent: "center", // Center items horizontally (if you want)
          }}
          id="verify-button"
        >
          Send Code
        </button>
      )}
    </div>
  );
};

export default LoginScreen;
