export const check = {
  v: "4.10.1",
  fr: 30,
  ip: 0,
  op: 40,
  w: 80,
  h: 80,
  nm: "Success Checkmark",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Check Mark",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [40, 40, 0], ix: 2 },
        a: { a: 0, k: [-1.312, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-15.75, 8],
                    [-8, 16],
                    [13.125, -4],
                  ],
                  o: [
                    [-15.75, 8],
                    [-8, 16],
                    [13.125, -4],
                  ],
                  v: [
                    [-15.75, 8],
                    [-8, 16],
                    [13.125, -4],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
              _render: true,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 25,
                    s: [0],
                    e: [100],
                  },
                  { t: 33 },
                ],
                ix: 1,
              },
              e: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
              _render: true,
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 2,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Circle Flash",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 25,
              s: [0],
              e: [98],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 30,
              s: [98],
              e: [0],
            },
            { t: 38 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [40, 40, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 25,
              s: [0, 0, 100],
              e: [100, 100, 100],
            },
            { t: 30 },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: "el",
          s: { a: 0, k: [64, 64], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: "Ellipse Path 1",
          mn: "ADBE Vector Shape - Ellipse",
          hd: false,
          _render: true,
        },
        {
          ty: "fl",
          c: { a: 0, k: [0.698, 0.18, 0.251, 1] },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          nm: "Fill 1",
          mn: "ADBE Vector Graphic - Fill",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Circle Stroke",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [39.022, 39.022, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 16,
              s: [100, 100, 100],
              e: [80, 80, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 22,
              s: [80, 80, 100],
              e: [120, 120, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 25,
              s: [120, 120, 100],
              e: [100, 100, 100],
            },
            { t: 29 },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [60, 60], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
              _render: true,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 0,
                    s: [0],
                    e: [100],
                  },
                  { t: 16 },
                ],
                ix: 1,
              },
              e: { a: 0, k: 0, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
              _render: true,
            },
            {
              ty: "st",
              c: { a: 0, k: [0.698, 0.18, 0.251, 1] },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 2,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
              _render: true,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0.978, 0.978], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
              _render: true,
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
      completed: true,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Circle Green Fill",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              n: ["0p833_0p833_0p167_0p167"],
              t: 21,
              s: [0],
              e: [98],
            },
            { t: 28 },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [40, 40, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 21,
              s: [0, 0, 100],
              e: [100, 100, 100],
            },
            { t: 28 },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: "el",
          s: { a: 0, k: [64, 64], ix: 2 },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: "Ellipse Path 1",
          mn: "ADBE Vector Shape - Ellipse",
          hd: false,
          _render: true,
        },
        {
          ty: "fl",
          c: { a: 0, k: [0.698, 0.18, 0.251, 1], ix: 4 },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          nm: "Fill 1",
          mn: "ADBE Vector Graphic - Fill",
          hd: false,
          _render: true,
        },
      ],
      ip: 0,
      op: 40,
      st: 0,
      bm: 0,
      completed: true,
    },
  ],
  __complete: true,
};
