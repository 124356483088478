import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, Spin, Upload } from "antd";
import React, { useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import heic2any from "heic2any";
import "cropperjs/dist/cropper.css";
import ImageUpload from "./ImageUpload";

const CropperModal = ({ visible, onClose, onSave }: any) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState("");

  const handleUpload = async (file: any) => {
    console.log("file: ", file);
    setLoading(true);
    setValidationError("");
    if (file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 0.9, // Adjust the quality setting here
        });
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result) {
            setLoading(false); // Set loading to false when upload is done
            setImage(reader.result.toString());
          }
        };
        reader.readAsDataURL(convertedBlob as any);
      } catch (error) {
        console.error("Error converting .heic file: ", error);
        setLoading(false); // Set loading to false on error
      }
    } else if (file.type === "image/x-adobe-dng") {
      setValidationError("DNG files are not supported");
      setLoading(false); // Set loading to false on error
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setImage(reader.result.toString());
          setLoading(false); // Set loading to false when upload is done
        }
      };
      reader.readAsDataURL(file);
    }
    return false; // Prevent upload
  };

  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      console.log("cropper: ", cropper);

      // Ensure canvas is ready
      const canvas = cropper.getCroppedCanvas({
        maxWidth: 800,
        maxHeight: 800,
      });

      if (!canvas) {
        alert("Canvas is not ready for cropping.");
        return;
      }

      // Log canvas details
      console.log("Canvas details: ", {
        width: canvas.width,
        height: canvas.height,
        aspectRatio: canvas.width / canvas.height,
      });

      // Use toBlob for better mobile compatibility
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result;
              console.log("Cropped image base64 data: ", base64data);
              onSave(base64data);
            };
            reader.readAsDataURL(blob);
          } else {
            alert("Failed to convert canvas to blob.");
          }
        },
        "image/jpeg",
        0.9
      ); // Adjust the quality setting here
    }
  };

  return (
    <Modal
      centered
      open={visible}
      onCancel={onClose}
      onOk={handleCrop}
      okText="Save"
      cancelText="Cancel"
    >
      <ImageUpload setImage={setImage} />
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 100, color: "#B22E40" }}
                spin
              />
            }
          />
        </div>
      )}
      {validationError && (
        <Alert message={validationError} type="error" showIcon />
      )}
      {image && (
        <Cropper
          src={image}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1}
          guides={false}
          ref={cropperRef}
        />
      )}
    </Modal>
  );
};

export default CropperModal;
