import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faGear } from "@fortawesome/free-solid-svg-icons";
import QRCodeGenerator from "../components/QRCodeGenerator";

const AdminPanel: React.FC = () => {
  const navigate = useNavigate();
  const topBarRef = useRef(null);
  const [qrCode, setQrCode] = useState<any>(null);
  const [result, setResult] = useState("");
  const [numberOfQRCodes, setNumberOfQRCodes] = useState(0);

  useEffect(() => {
    setQrCode(result);
  }, [result]);

  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };

  console.log("number in admin panel: ", numberOfQRCodes);

  return (
    <>
      <div
        style={{
          backgroundColor: "#1f2129",
          color: "white",
          minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "25px",
        }}
      >
        <FontAwesomeIcon
          // left align
          style={{ marginRight: "auto", marginLeft: "5px" }}
          size="xl"
          icon={faAngleLeft}
          color="white"
          onClick={() => navigate("/home")}
        />
        <div
          ref={topBarRef}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            alignSelf: "flex-end",
          }}
        >
          {/* <FontAwesomeIcon
      onClick={() => {
        navigate("/artist-setup-page");
      }}
      icon={faGear}
      size="2xl"
    /> */}
        </div>
        <div
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: 900,
            lineHeight: "41.86px",
            wordWrap: "break-word",
          }}
        >
          Admin Panel
        </div>
        <button
          style={{
            backgroundColor: "#B22E40",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontWeight: 700,
            fontSize: "16px",
            width: "150px",
            marginTop: "20px",
          }}
          onClick={() => navigate("/login-demo")}
        >
          Onboarding demo
        </button>
        <button
          style={{
            backgroundColor: "#B22E40",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontWeight: 700,
            fontSize: "16px",
            width: "150px",
            marginTop: "20px",
          }}
          onClick={() => navigate("/database")}
        >
          Database
        </button>
        {/* Main Content */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center children horizontally in the container
            justifyContent: "center", // Center children vertically in the container
            margin: "20px",
            gap: "20px", // Adds space between the input and the QRCodeGenerator
          }}
        >
          <input
            type="tel"
            onChange={(e) => setNumberOfQRCodes(parseInt(e.target.value))}
            placeholder="Number of QR codes"
            style={inputStyle}
          />
          <QRCodeGenerator
            numberOfQrCodes={numberOfQRCodes}
            baseUrl="http://artlink.network/artpiece"
          />
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
