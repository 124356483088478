import React, { useState, useEffect, CSSProperties } from "react";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import DMNICON2 from "../components/LandingPage/DMNFULLICON.png";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";

export const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const logoStyle: CSSProperties = {
    // width: "80px",
    height: "80px",
    marginRight: "2rem", // Reduced space after logo
    marginLeft: "0.5rem", // Reduced space after logo
    marginTop: "0.5rem",
  };

  const desktopMenuStyle: CSSProperties = {
    display: isMobile ? "none" : "flex",
    alignItems: "center",
    marginLeft: "0", // Remove default margin
  };

  const signinButtonStyle: CSSProperties = {
    backgroundColor: "#B22E40",
    color: "#ffffff",
    fontFamily: " Poppins",
    fontWeight: 700,
    border: "none",
    fontSize: "22px",
    borderRadius: "8px",
    marginLeft: "auto", // Push sign in to the right
    height: "36px",
    padding: "0 0.8rem",
  };

  const mobileMenuIconStyle: CSSProperties = {
    display: isMobile ? "flex" : "none",
    marginLeft: "auto",
    alignItems: "center",
  };

  const menuIconButtonStyle: CSSProperties = {
    fontSize: "24px",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const drawerMenuStyle: CSSProperties = {
    padding: "1rem",
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // const menuItems = [
  //   { key: "product", label: "Product", link: "/landing#product" },
  //   // { key: "features", label: "Features", link: "/landing" },
  //   { key: "pricing", label: "About Us", link: "/landing" },
  //   // { key: "community", label: "Community", link: "/landing" },
  // ];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkMobile = () => window.innerWidth <= 768;
    setIsMobile(checkMobile());

    const handleResize = () => {
      setIsMobile(checkMobile());
      if (!checkMobile()) {
        setVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className={`${isScrolled ? "active" : ""} navbar`}>
      {/* Logo */}
      <Link to={"/landing"}>
        <img className="logo" src={DMNICON2} alt="Logo" style={logoStyle} />
      </Link>

      <div className="menu-container-style">
        {/* Desktop Menu */}
        <div style={desktopMenuStyle}>
          <ul className="nav-menu">
            {/*  {menuItems.map((item) => (
              <li key={item.key}>
                <Link className="menu-items" to={`${item.link}`}>{item.label}</Link>
              </li>
            ))}*/}
          </ul>
          <Button
            style={signinButtonStyle}
            onClick={() => {
              navigate("/login");
            }}
          >
            Sign In
          </Button>
        </div>

        {/* Mobile Menu Icon */}
        <div style={mobileMenuIconStyle}>
          <Button
            className="menu-icon"
            type="text"
            icon={<MenuOutlined />}
            onClick={showDrawer}
            style={menuIconButtonStyle}
          />
        </div>
      </div>

      {/* Mobile Drawer */}
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={visible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="vertical" style={drawerMenuStyle}>
          {/*  {menuItems.map((item) => (
            <Menu.Item key={item.key} onClick={onClose}>
              <Link to={`${item.link}`}>{item.label}</Link>
            </Menu.Item>
          ))} */}
        </Menu>
        <div style={{ padding: "1rem" }}>
          <Button
            style={{ ...signinButtonStyle, width: "100%" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Sign In
          </Button>
        </div>
      </Drawer>
    </nav>
  );
};
